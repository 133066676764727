<template>
  <div>
    <b-modal id="uploadAttachment" title="Subir respuestas" modal-class="modal-warning" @show="show">
      <b-container>
        <b-row><span>{{ $t('Archivo de respuestas') }}</span></b-row>
        <b-row><b-form-file :disabled="loading.updatingData" v-model="attachmentFile" :state="Boolean(attachmentFile)" name="file" accept=".xlsx, .xls" class="cursor-pointer"
        :placeholder="$t('Elige un archivo...')" 
        :browse-text="$t('Buscar')" 
        /></b-row>
      </b-container>
      <template #modal-footer>
      <b-alert v-model="errorUpdate" v-height-fade.appear variant="danger" class="w-100">
        <div class="alert-body">
          {{ $t('Error al subir archivo') }}
        </div>
      </b-alert>
        <div class="w-100">
          <b-button v-if="errorUpdate" v-b-toggle.collapse-1 variant="gradient-secondary">
            {{ $t('Descripción') }}
          </b-button>
          <b-button class="float-right" :disabled="loading.updatingData" variant="warning" @click="uploadAttachment">
            <template v-if="loading.updatingData">
              <b-spinner small  /> {{ $t('Cargando Archivo') }}
            </template>
            <template v-else>
              {{ $t('Cargar Archivo') }}
            </template>
          </b-button>
        </div>
        <b-collapse id="collapse-1" class="mt-2 w-100">
          <div class="container--alert">
            <b-alert variant="dark" show>
              <h4 class="alert-heading">
                {{ $t('Listado de errores') }}
              </h4>
              <div class="alert-body" v-for="(errorItem, index) in dataErrorsFile" :key="index">
                <p>
                  <b-badge variant="light-danger">
                    {{errorItem.row}}
                  </b-badge> {{errorItem.error}}
                </p>
              </div>
            </b-alert>
          </div>
        </b-collapse>
      </template>
    </b-modal>
  </div>
</template>

<style>

.container--alert {
  max-height: 60vh;
  overflow-y: scroll;
}
</style>

<script>
import {
  BCollapse,
  BButton,
  VBToggle,
  BSpinner,
  BAlert,
  BContainer,
  BRow
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BaseServices from '@/store/services/index'

export default {
  name: 'modal-upload-attachment',
  components: {
    BButton,
    BCollapse,
    BAlert,
    BSpinner,
    BContainer,
    BRow
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      attachmentFile: null,
      baseService: new BaseServices(this),
      errorUpdate: false,
      dataErrorsFile: [],
      loading: {
        updatingData: false
      }
    }
  },
  watch: {
    mySession() {
      if (this.mySession?.id) this.setInitialData()
    }
  },
  mounted() {
    if (this.mySession?.id) this.setInitialData()
  },
  methods: {
    close() {
      this.$bvModal.hide('uploadAttachment')
    },
    show() {
      this.attachmentFile = null
      this.errorUpdate = false
      this.dataErrorsFile = []
      this.loading.updatingData = false
    },
    uploadAttachment() {
      this.loading.updatingData = true
      if (this.attachmentFile) {
        const params = {
          shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
        }

        this.baseService.callSpecialUploadFile('postFileRespondTickets', this.attachmentFile, params, this.$session.get('cas_user'))
          .then(response => {
            this.errorUpdate = false
            this.dataErrorsFile = []
            this.$success(response.message)
            this.close()
          })
          .catch(err => {
            this.errorUpdate = true
            this.dataErrorsFile = []
            this.dataErrorsFile = [...err.errors]
          }).finally(() => {
            this.loading.updatingData = false
          })
      } else {
        this.$alert(this.$t('msg-problema-cargar-datos', {
          code: 'Debe adjuntar un archivo'
        }))
        this.loading.updatingData = false
      }
      
    }
  }
}
</script>
