<template>
  <div>
    <b-modal centered id="guideTemplate" hide-footer :title="$t('¿Como completar la plantilla?')" size="lg" modal-class="modal-info">
      <iframe :src="guide" :title="$t('¿Como completar la plantilla?')" class="height-600 w-100"></iframe>
    </b-modal>
  </div>
</template>

<style>
.container--alert {
  max-height: 60vh;
  overflow-y: scroll;
}
.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  filter: invert(100%);
}
</style>

<script>

export default {
  name: 'modal-guide-template',
  components: {
  },
  computed: {
    guide() {
      return `https://storage.googleapis.com/tickets-misc/gestion_de_tickets_${this.countryCode}.pdf`
    },
    countryCode() {
      return this.$session.get('cas_user')?.country?.code ?? 'CL'
    }
  },
  data() {
    return {
      imgSelected: ''
    }
  },
  methods: {
    close() {
      this.$bvModal.hide('guideTemplate')
    }
  }
}
</script>
